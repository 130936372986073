var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.isLink && !_vm.variantButtonClass && !_vm.readMoreButton)?_c('router-link',{class:[
        'btn-default',
        _vm.size ? `btn-${_vm.size}` : '',
        _vm.outlined ? 'btn-border' : '',
        _vm.icon ? 'btn-icon' : ''
    ],attrs:{"to":_vm.url}},[_vm._v(" "+_vm._s(_vm.title)+" "),(_vm.icon)?_c('Icon',{staticClass:"icon",attrs:{"name":_vm.icon,"size":"20"}}):_vm._e()],1):(!_vm.isLink && !_vm.variantButtonClass && !_vm.readMoreButton)?_c('button',{class:[
        'btn-default',
        _vm.size ? `btn-${_vm.size}` : '',
        _vm.outlined ? 'btn-border' : '',
        _vm.icon ? 'btn-icon' : ''
    ],attrs:{"type":"button"},on:{"click":function($event){$event.preventDefault();return _vm.$emit('btnClick')}}},[_vm._v(" "+_vm._s(_vm.title)+" "),(_vm.icon)?_c('Icon',{staticClass:"icon",attrs:{"name":_vm.icon,"size":"20"}}):_vm._e()],1):(_vm.isLink && _vm.variantButtonClass && !_vm.readMoreButton)?_c('router-link',{class:[
        'btn-default',
        _vm.size ? `btn-${_vm.size}` : '',
        _vm.outlined ? 'btn-border' : '',
        _vm.icon ? 'btn-icon' : '',
        _vm.variantButtonClass ? _vm.variantButtonClass : ''
    ],attrs:{"to":_vm.url}},[_vm._t("default")],2):(!_vm.isLink && _vm.variantButtonClass && !_vm.readMoreButton)?_c('button',{class:[
        'btn-default',
        _vm.size ? `btn-${_vm.size}` : '',
        _vm.outlined ? 'btn-border' : '',
        _vm.icon ? 'btn-icon' : '',
        _vm.variantButtonClass ? _vm.variantButtonClass : ''
    ],attrs:{"type":"button"},on:{"click":function($event){$event.preventDefault();return _vm.$emit('btnClick')}}},[_vm._t("default")],2):(_vm.readMoreButton)?_c('router-link',{class:[
        'btn-read-more',
        _vm.size ? `btn-${_vm.size}` : '',
        _vm.outlined ? 'btn-border' : '',
        _vm.icon ? 'btn-icon' : '',
        _vm.variantButtonClass ? _vm.variantButtonClass : ''
    ],attrs:{"to":_vm.url}},[_vm._t("default")],2):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }