<template>
    <Layout>
        <Breadcrumb current-url="Accordion"/>


    </Layout>
</template>

<script>
    import Layout from '../components/common/Layout'
    import Breadcrumb from '../components/elements/breadcrumb/Breadcrumb'
    export default {
        name: 'BrandCarouselPage',
        components: {Layout, Breadcrumb}
    }
</script>